import { ComponentPropsWithoutRef } from 'react';

export const ExclamationTriangleFillIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6148 20.3544H3.38805C2.30506 20.3544 1.64293 19.1653 2.21328 18.2447L10.8526 4.29965C11.3938 3.42608 12.6653 3.42779 13.2041 4.30282L21.7915 18.2479C22.3585 19.1686 21.6961 20.3544 20.6148 20.3544ZM13.0011 10.5C13.0011 9.94771 12.5534 9.49999 12.0011 9.49999C11.4488 9.49999 11.0011 9.94771 11.0011 10.5V14.5001C11.0011 15.0524 11.4488 15.5001 12.0011 15.5001C12.5534 15.5001 13.0011 15.0524 13.0011 14.5001V10.5ZM12.0011 16.2502C11.4488 16.2502 11.0011 16.6979 11.0011 17.2502C11.0011 17.8025 11.4488 18.2502 12.0011 18.2502H12.0111C12.5634 18.2502 13.0111 17.8025 13.0111 17.2502C13.0111 16.6979 12.5634 16.2502 12.0111 16.2502H12.0011Z"
        fill="currentColor"
      />
    </svg>
  );
};
