import { ComponentProps } from 'react';

export const WarningCircleFillIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.9925 6.62503C10.9925 6.07275 10.5448 5.62503 9.99252 5.62503C9.44024 5.62503 8.99252 6.07275 8.99252 6.62503V11.125C8.99252 11.6773 9.44024 12.125 9.99252 12.125C10.5448 12.125 10.9925 11.6773 10.9925 11.125V6.62503ZM9.99252 12.375C9.44024 12.375 8.99252 12.8227 8.99252 13.375C8.99252 13.9273 9.44024 14.375 9.99252 14.375H10.0038C10.5561 14.375 11.0038 13.9273 11.0038 13.375C11.0038 12.8227 10.5561 12.375 10.0038 12.375H9.99252Z"
        fill="currentColor"
      />
    </svg>
  );
};
