import { ComponentProps } from 'react';

export const InfoCircleFillIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9.68634 4.80005C9.13406 4.80005 8.68634 5.24776 8.68634 5.80005C8.68634 6.35233 9.13406 6.80005 9.68634 6.80005H9.74908C10.3014 6.80005 10.7491 6.35233 10.7491 5.80005C10.7491 5.24776 10.3014 4.80005 9.74908 4.80005H9.68634ZM8.50003 7.50005C7.94775 7.50005 7.50003 7.94776 7.50003 8.50005C7.50003 9.05233 7.94775 9.50005 8.50003 9.50005H9.00574L9.00574 14.5C9.00574 14.7653 9.1111 15.0196 9.29863 15.2072C9.48617 15.3947 9.74052 15.5 10.0057 15.5H11.5C12.0523 15.5 12.5 15.0523 12.5 14.5C12.5 13.9478 12.0523 13.5 11.5 13.5H11.0057L11.0057 8.50005C11.0057 8.23483 10.9004 7.98048 10.7128 7.79294C10.5253 7.60541 10.271 7.50005 10.0057 7.50005H8.50003Z"
        fill="currentColor"
      />
    </svg>
  );
};
